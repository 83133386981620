@use "../../atoms/Grid/Grid.module.scss";

.blogPosts {
  margin-top: 7.8rem;
  margin-bottom: 7rem;

  @media (max-width: 768px) {
    margin: 4rem 0 3rem 0;
  }
}

.heading {
  grid-column: span 6;

  font-size: 6rem;
  font-weight: 400;
  line-height: 1.2;
  color: var(--grey900);

  @media (max-width: 1024px) {
    font-size: 4.8rem;
  }

  @media (max-width: 768px) {
    grid-column: span 12;

    font-size: 2.4rem;
    line-height: 1.2;
  }
}

.button {
  grid-column: span 6;
  justify-self: end;
  align-self: end;
  width: fit-content;
  height: fit-content;

  @media (max-width: 768px) {
    grid-column: span 12;
    grid-row: 3;
    align-self: start;
    justify-self: start;
  }
}

.sliderWrapper {
  grid-column: span 12;
  margin: 2.6rem 0 2rem 0;

  & .swiper-wrapper {
    align-items: stretch;
  }

  & :global(.swiper-slide) {
    width: Grid.gridColsToWidth(4);
    height: auto;

    @media (max-width: 1024px) {
      width: Grid.gridColsToWidth(5);
    }

    @media (max-width: 768px) {
      width: Grid.gridColsToWidth(9);
    }
  }
}