.section {
  --column-gap: 20px;

  display: grid;
  column-gap: var(--column-gap);
  grid-template-columns: repeat(12, minmax(0, 1fr));

  @media screen and (max-width: 1280px) {
    --column-gap: 16px;
  }
  @media screen and (max-width: 1024px) {
    --column-gap: 12px;
  }
  @media screen and (max-width: 768px) {
    --column-gap: 8px;
  }
}

@function gridColsToWidth($columns) {
  @return calc((100% - var(--column-gap) * 11) / 12 * $columns + var(--column-gap) * ($columns - 1));
}